<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Veranstalter">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="handleRegionChange"
        ></region-chooser>
        <el-button type="primary" icon="fal fa-plus" @click="newOrganization">
          Neuer Veranstalter
        </el-button>
      </template>

      <el-input
        v-model="computedSearchQuery"
        placeholder="Durchsuchen…"
        clearable
        style="max-width: 500px;"
      >
        <i slot="prefix" class="el-icon-search el-input__icon"></i>
      </el-input>

      <data-loader
        ref="dataloader"
        :endpoint="
          `/admin/support_organizations?page=${currentPage}&query=${remoteSearchQuery}&region_id=${$store.state.auth.chosenRegion}`
        "
      >
        <template #loaded="{data}">
          <el-table
            style="width: 100%;"
            empty-text="Keine Ergebnisse"
            stripe
            :data="data.support_organizations"
          >
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column label="Veranstaltungen">
              <template #default="scope">
                <router-link
                  v-if="scope.row.num_upcoming_events > 0"
                  class="el-link el-link--primary is-underline"
                  :to="{
                    name: 'EventsPage',
                    query: { organizationID: scope.row.id }
                  }"
                >
                  {{ scope.row.num_upcoming_events }}
                </router-link>
                <span v-else>{{ scope.row.num_upcoming_events }}</span>
              </template>
            </el-table-column>
            <el-table-column label="ohne Prüfung eintragen">
              <template #default="scope">
                <el-tag
                  v-if="scope.row.trusted === false"
                  size="small"
                  type="success"
                >
                  Nein
                </el-tag>
                <el-tag v-else size="small" type="warning">
                  Ja
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="contact_person_name"
              label="Kontaktperson"
            ></el-table-column>
            <el-table-column label="Aktionen" width="260">
              <template #default="scope">
                <el-button
                  icon="fal fa-edit"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  Bearbeiten
                </el-button>
                <delete-button
                  v-slot="slotProps"
                  :on-delete="deleteOrganization"
                  subject="Veranstalter"
                >
                  <el-button
                    size="mini"
                    icon="fal fa-trash-alt"
                    type="danger"
                    @click="slotProps.handleDelete(scope.row)"
                  >
                    Löschen
                  </el-button>
                </delete-button>
              </template>
            </el-table-column>
          </el-table>

          <the-pagination
            v-model="currentPage"
            :total-count="data.total_count"
          ></the-pagination>
        </template>
      </data-loader>
    </page-content>
  </div>
</template>

<script>
import * as log from "loglevel"
import { debounce } from "lodash-es"
import SupportOrganizationsRepository from "@/repositories/support_organizations_repository.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DataLoader from "@/components/DataLoader"
import ThePagination from "@/components/ThePagination"
import DeleteButton from "@/components/DeleteButton.js"

export default {
  metaInfo: {
    title: "Veranstalter"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    DataLoader,
    DeleteButton,
    ThePagination
  },
  beforeRouteUpdate(to, from, next) {
    log.info("view: beforeRouteUpdate")

    if (from.name === to.name) {
      if (to.query.page) {
        log.info(`setting current page to ${to.query.page}`)
        this.currentPage = parseInt(to.query.page)
      } else {
        log.info(`setting current page to 1`)
        this.currentPage = 1
      }

      if (to.query.query) {
        log.info(`setting query to ${to.query.query}`)
        this.localSearchQuery = to.query.query
        this.remoteSearchQuery = to.query.query
      } else {
        log.info(`setting query to ""`)
        this.localSearchQuery = ""
        this.remoteSearchQuery = ""
      }
    }
    next()
  },
  data() {
    return {
      currentPage: parseInt(this.$route.query.page) || 1,
      localSearchQuery: this.$route.query.query || "",
      remoteSearchQuery: this.$route.query.query || ""
    }
  },
  computed: {
    computedSearchQuery: {
      get() {
        return this.localSearchQuery
      },
      set(value) {
        this.localSearchQuery = value
        this.debouncedSearch(value)
      }
    }
  },
  methods: {
    handleRegionChange() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: 1,
          query: "",
          region_id: this.$store.state.auth.chosenRegion
        }
      })
    },
    debouncedSearch: debounce(
      async function(query) {
        log.info(`Searching for "${query}"…`)
        this.remoteSearchQuery = query
        this.currentPage = 1
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: 1,
            query: query
          }
        })
      },
      300,
      { maxWait: 3000 }
    ),
    handleEdit(organization) {
      this.$router.push({
        name: "EditSupportOrganizationPage",
        params: { id: organization.id }
      })
    },
    async deleteOrganization(organization) {
      await SupportOrganizationsRepository.destroy(organization.id)
      this.$refs.dataloader.loadData()
    },
    newOrganization() {
      this.$router.push({ name: "NewSupportOrganizationPage" })
    }
  }
}
</script>
